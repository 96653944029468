import React from 'react';

import { Outlet, useLocation } from 'react-router-dom';

import MultiStepHeader from '@/components/molecules/MultiStepHeader';
import LandingTemplate from '@/components/templates/LandingTemplate';

import { useDeviceType } from '@/hooks/useDeviceType';

const steps = {
  profile: {
    index: 1,
    title: 'PROFILE CREATION',
    description: 'Create your profile in a few simple steps and start enjoying a customized experience'
  },
  overview: {
    index: 2,
    title: 'Onboarding',
    description:
      'Get ready to explore everything we have to offer. We’re here to guide you through our platform, introduce key features, and help you make the most of your experience. Let’s get started!'
  },
  subscription: {
    index: 3,
    title: 'SELECT SUBSCRIPTION PLAN',
    description:
      'Choose the subscription that fits your needs. Whether you’re looking for the basics or the full suite of features, we’ve got you covered.'
  }
};

const OnboardingLayout = () => {
  const { isMobileView } = useDeviceType();
  const location = useLocation();

  const getCurrentStep = () => {
    const pathParts = location.pathname.split('/');
    const currentStep = pathParts[pathParts.length - 1] as keyof typeof steps;

    return (
      steps[currentStep] || {
        index: 1,
        title: 'ONBOARDING',
        description: "Get ready to explore everything we have to offer. We're here to guide you through our platform."
      }
    );
  };

  return (
    <LandingTemplate>
      <div className="max-w-[700px] mx-auto h-full flex flex-col min-h-full md:max-h-full md:h-fit pb-7 md:pr-7 md:pt-8 md:pb-0">
        {!isMobileView && (
          <MultiStepHeader
            currentStep={getCurrentStep().index}
            totalSteps={3}
            title={getCurrentStep().title}
            description={getCurrentStep().description}
          />
        )}
        <Outlet />
      </div>
    </LandingTemplate>
  );
};

export default OnboardingLayout;
