import React from 'react';

import ResetPasswordForm from '@/components/organisms/ResetPasswordForm';
import LandingTemplate from '@/components/templates/LandingTemplate';

const ResetPasswordPage = () => {
  return (
    <LandingTemplate>
      <ResetPasswordForm />
    </LandingTemplate>
  );
};

export default ResetPasswordPage;
