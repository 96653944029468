import React from 'react';
import { UseFormRegister, FieldError, FieldErrors } from 'react-hook-form';

type FormFieldError = FieldError | undefined | FieldErrors<any>;

interface CheckboxProps {
  name: string;
  label?: string | React.ReactNode;
  checked?: boolean;
  onChange?: (checked: boolean) => void;
  disabled?: boolean;
  className?: string;
  register?: UseFormRegister<any>;
  error?: FormFieldError;
}

const Checkbox: React.FC<CheckboxProps> = ({
  name,
  label,
  checked,
  onChange,
  disabled = false,
  className = '',
  register,
  error
}) => {
  const isControlled = checked !== undefined && onChange !== undefined;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (isControlled && onChange) {
      onChange(event.target.checked);
    }
  };

  const inputProps = isControlled ? { checked, onChange: handleChange } : register ? { ...register(name) } : {};

  const errorMessage = error && 'message' in error ? error.message : '';

  return (
    <div className={`inline-flex flex-col items-start ${className}`}>
      <label className="inline-flex items-center cursor-pointer gap-1">
        <div className="relative">
          <input type="checkbox" className="sr-only peer" disabled={disabled} {...inputProps} />
          <div
            className={`w-[20px] h-[20px] flex items-center justify-center bg-white border-2 rounded-[6px]
            ${disabled ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'}
            ${error ? 'border-red-500' : 'border-gray-300'}
            peer-checked:bg-[#7630F7] peer-checked:border-[#7630F7]
          `}
          >
            <svg
              className="w-[0.8rem] h-[0.8rem] text-white absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] opacity-100 peer-checked:opacity-100"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="3.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <polyline points="20 6 9 17 4 12"></polyline>
            </svg>
          </div>
        </div>
        {label && <span className={`ml-2 ${disabled ? 'text-gray-400' : 'text-gray-700'}`}>{label}</span>}
      </label>
      {errorMessage && <p className="mt-1 text-xs text-red-500">{errorMessage as string}</p>}
    </div>
  );
};

export default Checkbox;
