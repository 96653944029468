import React, { useState } from 'react';

import Checkbox from '@/components/atoms/Checkbox';
import CountdownTimer from '@/components/atoms/CountDownTimer';
import InputField from '@/components/atoms/InputField';
import PasswordField from '@/components/atoms/PasswordField';
import { Button } from '@/components/ui/button';

import { useDeviceType } from '@/hooks/useDeviceType';

import Logo from '@/assets/images/logo512.png';

const ForgotPasswordForm = () => {
  const [email, setEmail] = useState();
  const [isTimerActive, setIsTimerActive] = useState(false);

  const { isMobileView } = useDeviceType();

  return (
    <div className="pb-10 h-full max-w-[616px] mx-auto laptop-h:h-fit md:pr-7 md:pt-8 border md:border-0">
      <form
        className={`max-w-[450px] mt-7 gap-5 mx-auto flex flex-col ${isMobileView ? 'justify-between' : 'justify-center'} py-10 px-5 md:px-0 h-full laptop-h:h-fit`}
      >
        <div>
          <img className="max-w-[130px] mx-auto md:hidden" src={Logo} alt="batjet-logo" />
          <h1 className="mt-7 mb-2 text-xl text-center font-bold uppercase md:text-left md:text-[28px]">
            FORGOT PASSWORD
          </h1>
          <p className="hidden md:block text-lg text-secondary leading-[22.5px]">
            Please enter your email address to receive a link to reset your password. Click the link in your email to
            create a new password and regain access to your account.
          </p>
          <InputField
            className="my-4 mt-5"
            label={' Mail'}
            placeholder="Enter mail"
            description="A recovery link will sent into your email"
            name="email"
            type="text"
            value={email}
            onChange={(e: any) => setEmail(e.target.value)}
          />
        </div>
        <div>
          <Button
            type="button"
            onClick={() => {
              setIsTimerActive(true);
            }}
            className="my-4 w-full rounded-[24px] text-base font-semibold h-[44px] md:h-[64px] md:text-[18px] uppercase"
            disabled={!email || isTimerActive}
          >
            Send Mail
          </Button>
          <p className="text-sm md:text-lg text-center">
            {isTimerActive ? 'Please wait before able to resend mail' : ' Does not get a mail?'}{' '}
            {isTimerActive ? (
              <span className="text-primary font-semibold">
                <CountdownTimer
                  duration={60}
                  isActive={isTimerActive}
                  onActiveChange={setIsTimerActive}
                  onComplete={() => {
                    // Do something when timer completes
                    console.log('Timer completed');
                  }}
                />
              </span>
            ) : (
              <button /*onClick={handleSignUpClick}*/ className="text-primary font-bold">Resend</button>
            )}
          </p>
        </div>
      </form>
    </div>
  );
};

export default ForgotPasswordForm;
