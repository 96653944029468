import React from 'react';

interface MultiStepHeaderProps {
  currentStep: number;
  totalSteps: number;
  title: string;
  description: string;
}

const MultiStepHeader: React.FC<MultiStepHeaderProps> = ({ currentStep, totalSteps, title, description }) => {
  return (
    <div className="w-full hidden md:block">
      <div className="w-full flex justify-between items-start gap-2 mb-2">
        {Array.from({ length: totalSteps }, (_, index) => (
          <span
            key={index}
            className={`flex-1 h-[4px] rounded-[2px] ${index < currentStep ? 'bg-primary' : 'bg-lightGray'}`}
          ></span>
        ))}
      </div>
      <p className="uppercase font-bold text-[28px]">{title}</p>
      <p className="text-secondary text-lg leading-[22px]">{description}</p>
    </div>
  );
};

export default MultiStepHeader;
