import LandingTemplate from '@/components/templates/LandingTemplate';
import WelcomeSection from '@/components/molecules/WelcomeSection';

const LandingPage = () => {
  return (
    <LandingTemplate>
      <WelcomeSection></WelcomeSection>
    </LandingTemplate>
  );
};

export default LandingPage;
