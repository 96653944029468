import React from 'react';

import checkSuccessImg from '@/assets/images/check-success.svg';

interface SuccessMessageProps {
  title: string;
  description: string;
}

const SuccessMessage: React.FC<SuccessMessageProps> = ({ title, description }) => {
  return (
    <div className="w-full flex-1 flex flex-col items-center justify-center gap-2">
      <img className="w-[144px] h-[144px]" src={checkSuccessImg} alt="success-img" />
      <p className="font-bold text-2xl">{title}</p>
      <p className="text-secondary text-[18px] leading-[22px] text-center max-w-[580px]">{description}</p>
    </div>
  );
};

export default SuccessMessage;
