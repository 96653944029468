import logo from '../../assets/images/logo512.png';
import imgGradient from '../../assets/images/img-splash-gradient.png';

const SplashScreen = () => {
  return (
    <div className="fixed inset-0 flex flex-col items-center justify-center bg-white z-50">
      <img className="max-w-[50%] mx-auto" src={logo} alt="batjet-logo" />
      <img className="absolute top-0 left-0" src={imgGradient} alt="gradient" />
      <img className="absolute bottom-0 right-0 rotate-[180deg]" src={imgGradient} alt="gradient" />
    </div>
  );
};

export default SplashScreen;
