export const terms = [
  {
    title: 'BMEV (Budget Maximum Expense Value):',
    description:
      'BMEV is the maximum spending limit set in the budget. Each entity in the budget must not exceed the spending limit set by the BMEV. '
  },
  {
    title: 'BTRT (Budget Total Revenue Target):',
    description:
      'BTRT is the revenue target set in the budget. Each entity in the budget is expected to achieve the revenue target. '
  },
  {
    title: 'Bank Initial Amount:',
    description:
      'Bank Initial Amount is the initial balance assigned to each bank account created. This balance serves to fund each invoice and should have a value greater than BMEV to ensure sufficient funds are available.'
  },
  {
    title: 'MAE (Managerial Accounting Entities):',
    description:
      'MAE is an entity that can be customized by users. Users can allocate invoices to each MAE to effectively manage cashflow.'
  },
  {
    title: 'AC (Accounting Center):',
    description:
      'ACs are sub-entities that divided into 4 categories: Department, Product and Services, Projects, and Product. Each AC has ACMEV and ACRT which have the same value as BMEV and BTRT.'
  },
  {
    title: 'ACMEV (Accounting Center Maximum Expense Value):',
    description:
      'ACMEV is the maximum expense value for one accounting center. Each AC can have its own ACMEV value, but the total ACMEV for each AC category must be equal to BMEV.'
  },
  {
    title: 'ACRT (Accounting Center Revenue Target):',
    description:
      'ACRT is the revenue target for one accounting center. each AC can have its own ACRT value, but the total ACRT for each AC category must be equal to BTRT.'
  },
  {
    title: 'EC (Expense Category):',
    description: 'ECs are sub-entities that used for expense allocation. Users can create custom ECs.'
  },
  {
    title: 'RC (Revenue Category):',
    description: 'RCs are sub-entities that used for revenue allocation. Users can create custom RCs.'
  },
  {
    title: 'ECMEV (Expense Category Maximum Expense Value):',
    description:
      'ECMEV is the maximum expense value for one EC. Each EC can have its own ECMEV value, but the total ECMEV for each EC must be equal to BMEV.'
  },
  {
    title: 'RCRT (Revenue Category Revenue Target):',
    description:
      'RCRT is the revenue target for one RC. each RCRT can have its own RCRT value, but the total RCRT for each RC must be equal to BTRT.'
  },
  {
    title: 'Invoice:',
    description:
      'An invoice can be an expense or revenue that is listed in the budget. Invoices must be allocated to: Bank Account, Each Category of ACs, and EC/RC depending on the invoice type. '
  },
  {
    title: 'Commitments:',
    description:
      'Commitments are invoices that have been added but not yet paid. Users will get commitment payment date information on the budget.'
  }
];
