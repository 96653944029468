import { FieldError, FieldErrors, UseFormRegister } from 'react-hook-form';

import React from 'react';

import * as Switch from '@/components/ui/switch';

type FormFieldError = FieldError | undefined | FieldErrors<any>;

interface SwitchFieldProps {
  name: string;
  label?: string;
  description?: string;
  checked?: boolean;
  onCheckedChange?: (checked: boolean) => void;
  disabled?: boolean;
  register?: UseFormRegister<any>;
  error?: FormFieldError;
  className?: string;
}

const SwitchField: React.FC<SwitchFieldProps> = ({
  name,
  label,
  description,
  checked = false,
  onCheckedChange,
  disabled = false,
  register,
  error,
  className
}) => {
  const handleChange = (checked: boolean) => {
    if (register) {
      const { onChange: registeredOnChange } = register(name);
      registeredOnChange({ target: { value: checked, name } });
    }
    if (onCheckedChange) {
      onCheckedChange(checked);
    }
  };

  const errorMessage = error && 'message' in error ? error.message : '';

  return (
    <div className={`${className || ''}`}>
      <div className="flex items-center justify-between md:flex-col md:items-start">
        {label && <label className="text-[#495057] font-medium text-xs md:text-lg md:mb-2">{label}</label>}
        <Switch.Switch
          checked={checked}
          onCheckedChange={handleChange}
          disabled={disabled}
          className={`
            data-[state=checked]:bg-primary
            data-[state=unchecked]:bg-[#E9ECEF]
          `}
        />
      </div>
      {(errorMessage || description) && (
        <p className={`mt-2 text-xs ${errorMessage ? 'text-red-600' : 'text-[#93999F]'}`}>
          {(errorMessage as String) || description}
        </p>
      )}
      {register && <input type="hidden" name={name} value={checked.toString()} />}
    </div>
  );
};

export default SwitchField;
