import isMobile from 'is-mobile';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import React, { useEffect, useState } from 'react';
import { userService } from '@/services/user.service';
import { useNavigate } from 'react-router-dom';

import SuccessMessage from '@/components/molecules/SuccessMessage';
import toast from 'react-hot-toast';
import { useWidth } from '@/hooks/useWidth';

import cameraIcon from '@/assets/icons/camera.svg';
import defaultUserImg from '@/assets/images/default-user.png';

import InputField from '../atoms/InputField';
import InfoModal from '../molecules/InfoModal';
import UserProfilePicInput from '../molecules/UserProfilePicInput';
import { Button } from '../ui/button';
import { useAuth } from '@/hooks/useAuth';

import { zodResolver } from '@hookform/resolvers/zod';

const profileSchema = z.object({
  firstName: z.string().min(1, 'First name is required'),
  lastName: z.string().optional(),
  username: z.string().min(1, 'Username is required'),
  email: z.string().email(),
  phone: z.string().min(1, 'Phone number is required')
});

type ProfileFormData = z.infer<typeof profileSchema>;

const ProfileCreationForm = () => {
  const [showSuccess, setShowSuccess] = useState(false);
  const [selectedImage, setSelectedImage] = useState<File | null>(null);

  const navigate = useNavigate();
  const { user } = useAuth();
  const width = useWidth();
  const isMobileView = width < 768 || isMobile() ? true : false;

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting }
  } = useForm<ProfileFormData>({
    resolver: zodResolver(profileSchema),
    defaultValues: {
      email: ''
    }
  });

  // update email when user data becomes available
  useEffect(() => {
    if (user?.email) {
      setValue('email', user.email);
    }
  }, [user, setValue]);

  const handleImageChange = (file: File | null) => {
    setSelectedImage(file);
  };

  const handleImageRemove = () => {
    setSelectedImage(null);
  };

  const handleOnBoardingClick = () => {
    navigate('/onboarding/overview');
  };

  const onSubmit = async (data: any) => {
    try {
      const profile = await userService.createProfile({
        firstName: data.firstName,
        lastName: data.lastName,
        username: data.username,
        email: data.email,
        phone: data.phone,
        profile_pic: selectedImage || undefined
      });

      toast.success('Profile created successfully!');
      setShowSuccess(true);
      handleOnBoardingClick();
    } catch (error) {
      console.error('Failed to create profile:', error);
    }
  };

  return (
    <div
      className={`w-full pb-10 flex-1 h-full mx-auto laptop-h:h-fit ${showSuccess ? 'flex flex-col justify-between' : ''}`}
    >
      {(!showSuccess || isMobileView) && (
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="px-4 md:px-0 flex flex-col justify-center items-center md:items-start md:mt-4 py-3"
        >
          <UserProfilePicInput
            defaultUserImg={defaultUserImg}
            cameraIcon={cameraIcon}
            onImageChange={handleImageChange}
            onImageRemove={handleImageRemove}
          />
          <div className="w-full mt-6 flex items-start gap-4">
            <InputField
              label={'Firstname'}
              placeholder="Firstname..."
              name="firstName"
              type="text"
              register={register}
              className="flex-1"
              error={errors.firstName}
            />
            <InputField
              label={'Lastname'}
              placeholder="Lastname..."
              name="lastName"
              type="text"
              register={register}
              className="flex-1"
            />
          </div>
          <div className="w-full flex flex-col items-start gap-x-4 md:flex-row">
            <InputField
              className="w-full mt-5"
              label={'Username'}
              placeholder="Username..."
              name="username"
              type="text"
              register={register}
              error={errors.username}
            />
            <InputField
              className="w-full mt-5"
              label={'Mail'}
              placeholder="Mail..."
              name="email"
              type="text"
              register={register}
              disabled
            />
          </div>
          <div className="w-full flex flex-col items-start gap-x-4 md:flex-row">
            <InputField
              className="w-full mt-5 md:w-1/2 md:ml-0 md:mr-auto"
              label={'Phone Number'}
              placeholder="Enter phone number..."
              name="phone"
              type="text"
              register={register}
              error={errors.phone}
            />
            <span></span>
          </div>

          <div className="flex items-center flex-col md:flex-row-reverse w-full justify-between md:mt-[5.4rem]">
            <Button
              type="submit"
              className="mt-[5.4rem] mb-5 w-full rounded-[24px] text-base font-semibold h-[44px] md:mb-0 md:mt-0 md:h-[64px] md:text-[18px] md:w-fit md:px-7"
              disabled={isSubmitting}
            >
              {isSubmitting ? 'CREATING AN ACCOUNT...' : 'CREATE AN ACCOUNT'}
            </Button>
            <button
              disabled={isSubmitting}
              type="button"
              className="text-primary font-semibold  md:mt-2 w-fit"
              onClick={handleOnBoardingClick}
            >
              Skip for now
            </button>
          </div>
        </form>
      )}

      {showSuccess && !isMobileView && (
        <>
          <SuccessMessage title="Account Created" description="You have successfully created an account in BatJet!" />

          <Button
            onClick={handleOnBoardingClick}
            className="mt-[5.4rem] mb-5 w-fit rounded-[24px] text-base font-semibold px-6 h-[64px] text-[18px] ml-auto mr-0"
          >
            GO TO ONBOARDING
          </Button>
        </>
      )}

      {showSuccess && isMobileView && (
        <InfoModal
          isOpen={showSuccess}
          variant="success"
          title="Account Created"
          message="You have successfully created an account in BatJet!"
          buttonText="Go to Onboarding"
          onButtonClick={handleOnBoardingClick}
        />
      )}
    </div>
  );
};

export default ProfileCreationForm;
