import * as SliderPrimitive from '@radix-ui/react-slider';

import * as React from 'react';

import { cn } from '@/lib/utils';

interface SliderProps extends React.ComponentPropsWithoutRef<typeof SliderPrimitive.Root> {
  thumb?: React.ReactNode;
}

const Slider = React.forwardRef<React.ElementRef<typeof SliderPrimitive.Root>, SliderProps>(
  ({ className, thumb, ...props }, ref) => (
    <SliderPrimitive.Root
      ref={ref}
      className={cn('relative flex w-full touch-none select-none items-center', className)}
      {...props}
    >
      <SliderPrimitive.Track className="relative h-1 w-full grow overflow-hidden rounded-full bg-[#E9ECEF]">
        <SliderPrimitive.Range className="absolute h-full bg-primary" />
      </SliderPrimitive.Track>
      {/* Position the thumb relative to the track */}
      <SliderPrimitive.Thumb className="relative min-w-[40px] flex items-center justify-center">
        {thumb || <div className="block h-5 w-7 rounded-full border-2 border-primary bg-background" />}
      </SliderPrimitive.Thumb>
    </SliderPrimitive.Root>
  )
);

Slider.displayName = SliderPrimitive.Root.displayName;

export { Slider };
