import LoginForm from '@/components/organisms/LoginForm';
import LandingTemplate from '@/components/templates/LandingTemplate';
import React from 'react';

const LoginPage = () => {
  return (
    <LandingTemplate>
      <LoginForm />
    </LandingTemplate>
  );
};

export default LoginPage;
