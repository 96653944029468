import React from 'react';
import dashboardIcon from '@/assets/icons/dashboard.svg';
import searchIcon from '@/assets/icons/search.svg';
import bellIcon from '@/assets/icons/bell.svg';
import ProfileDropdown from '@/components/molecules/ProfileDropdown';

const DashboardHeader = () => {
  return (
    <header className="sticky w-full top-0 z-40 h-[64px] shadow-[0px_2px_4px_0px_#00000040] bg-white flex items-center justify-between px-7">
      {/* PAGE TITLE */}
      <h2 className="flex items-center gap-2 font-semibold">
        <img src={dashboardIcon} alt="dashboard icon" />
        <span>Dashboard</span>
      </h2>

      <div className="flex items-center gap-9">
        {/* SEARCH */}
        <div className="relative flex bg-lightGray rounded-[16px] overflow-hidden h-[40px] w-[303px]">
          <img className="absolute top-[50%] translate-y-[-50%] left-[15px]" src={searchIcon} alt="search icon" />

          <input
            className="bg-transparent text-xs font-medium w-full placeholder:text-secondary placeholder:text-xs placeholder:font-medium pl-[43px] rounded-[16px]"
            type="text"
            placeholder="Search for Budgets, Accounts, etc..."
          />
        </div>

        {/* NOTIFICATIONS BELL */}
        <button>
          <img src={bellIcon} alt="bell icon" />
        </button>

        {/* PROFILE DROPDOWN */}
        <ProfileDropdown name="Marco Bertolazzi" />
      </div>
    </header>
  );
};

export default DashboardHeader;
