import icon1 from '@/assets/budget_icons/icon-1.svg';
import icon2 from '@/assets/budget_icons/icon-2.svg';
import icon3 from '@/assets/budget_icons/icon-3.svg';

export const BUDGET_ICONS = [
  {
    id: 'icon-1',
    name: 'Icon1',
    icon: icon1
  },
  {
    id: 'icon-2',
    name: 'Icon2',
    icon: icon2
  },
  {
    id: 'icon-3',
    name: 'Icon3',
    icon: icon3
  }
];

export const getIconById = (id: string) => BUDGET_ICONS.find((icon) => icon.id === id);

export const getIconPath = (id: string) => {
  const icon = getIconById(id);
  return icon ? icon.icon : icon1;
};
