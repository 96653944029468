import React from 'react';

import MobileAppBar from '@/components/molecules/MobileAppBar';
import ProfileCreationForm from '@/components/organisms/ProfileCreationForm';

const ProfilePage = () => {
  return (
    <>
      <MobileAppBar title="Profile Creation" />
      <ProfileCreationForm />
    </>
  );
};

export default ProfilePage;
