import React, { useState } from 'react';

import InfoModal from '@/components/molecules/InfoModal';
import MobileAppBar from '@/components/molecules/MobileAppBar';
import SubscriptionPlanBox from '@/components/molecules/SubscriptionPlanBox';
import SuccessMessage from '@/components/molecules/SuccessMessage';
import { Button } from '@/components/ui/button';

import { useDeviceType } from '@/hooks/useDeviceType';

import iconCheck from '@/assets/icons/check.svg';
import { useNavigate } from 'react-router-dom';

interface Plan {
  id: 'basic' | 'standard' | 'pro';
  name: string;
  price: string;
  benefits: string[];
}

const SubscriptionPage: React.FC = () => {
  const [selectedPlan, setSelectedPlan] = useState<string>('basic');
  const [isPaid, setIsPaid] = useState(false);

  const navigate = useNavigate();
  const { isMobileView } = useDeviceType();

  const plans: Plan[] = [
    {
      id: 'basic',
      name: 'Basic',
      price: 'Free',
      benefits: [
        'Maximum 2 Budgets',
        'Maximum 2 Bank Accounts for each Budget',
        'Maximum 6 Accounting Center for each Budget'
      ]
    },
    {
      id: 'standard',
      name: 'Standard',
      price: '€10',
      benefits: [
        'Able to Invite other user to join Budget',
        'Maximum 5 Budgets',
        'Unlimited number of Bank Account and Accounting Center'
      ]
    },
    {
      id: 'pro',
      name: 'Pro',
      price: '€20',
      benefits: [
        'Able to Invite other user to join Budget',
        'Unlimited number of Budget',
        'Unlimited number of Bank Account and Accounting Center'
      ]
    }
  ];

  const selectedPlanBenefits = plans.find((item) => item?.id === selectedPlan)?.benefits || [];

  const handlePlanSelect = (planId: string): void => {
    setSelectedPlan(planId);
  };

  const handleContinueClick = () => {
    if (selectedPlan === 'basic') {
      navigate('/dashboard');
    } else {
      setIsPaid(true);
    }
  };

  return (
    <>
      <MobileAppBar title="Subscription Plan" />
      {isPaid ? (
        <SuccessMessage
          title="Payment Success"
          description="You have successfully paid the Pro Subscription Plan. you can change it on profile page"
        />
      ) : (
        <div className="p-4 h-full md:flex-1 md:p-0 md:flex md:flex-col">
          <p className="text-base font-bold mb-[0.125rem] md:hidden">Select Subscription Plan</p>
          <p className="text-sm text-gray md:text-lg md:text-secondary md:mt-2">
            <span className="hidden md:inline-block">*</span> You can change it later on profile page
          </p>

          <div className="flex-1 mt-6 mb-[1.7rem] flex flex-col gap-4 md:flex-row">
            {plans.map((plan) => (
              <SubscriptionPlanBox
                key={plan.id}
                plan={plan}
                isActive={selectedPlan === plan.id}
                onSelect={handlePlanSelect}
              />
            ))}
          </div>

          <div className="md:hidden">
            <p className="text-sm">Benefit</p>
            <ul className="mt-[0.9rem] flex flex-col gap-4">
              {selectedPlanBenefits?.length > 0 &&
                selectedPlanBenefits?.map((item) => (
                  <li className="flex items-start gap-[0.6rem]">
                    <span className="flex items-center justify-center rounded-full bg-lightPurple w-[20px] h-[20px]">
                      <img src={iconCheck} alt="check-icon" />
                    </span>
                    <p className="leading-[19.5px]">{item}</p>
                  </li>
                ))}
            </ul>
          </div>
        </div>
      )}
      <footer className="px-4 md:px-0 md:flex md:items-center md:gap-14 md:pb-4 md:mt-2">
        {selectedPlan !== 'basic' && (
          <p className="mb-4 text-xs font-medium leading-[14.6px] text-gray md:text-sm md:text-secondary">
            by Clicking “Continue to payment” button means you agree to billed annually.
          </p>
        )}
        <Button
          className="rounded-full mb-0 mt-auto uppercase text-base flex-1 w-full md:block ml-auto mr-0 md:min-h-[64px] md:px-7 md:text-lg md:w-fit md:rounded-[24px] md:flex-none"
          onClick={handleContinueClick}
        >
          continue to {`${selectedPlan !== 'basic' ? 'Payment' : 'DASHBOARD'}`}
        </Button>
      </footer>

      {isPaid && isMobileView && (
        <InfoModal
          isOpen={isPaid}
          variant="success"
          title="Payment Success"
          message="You have successfully paid the Pro Subscription Plan. you can change it on profile page"
          buttonText="Go to Dashboard"
          onButtonClick={handleContinueClick}
        />
      )}
    </>
  );
};

export default SubscriptionPage;
