import { FC, ReactElement } from 'react';
import { useAuth } from '@/hooks/useAuth';
import { Navigate, useLocation } from 'react-router-dom';
import React from 'react';

interface PublicRouteProps {
  children: ReactElement;
  redirectTo?: string;
}

const PublicRoute: FC<PublicRouteProps> = ({ children, redirectTo = '/dashboard' }) => {
  const { user, loading } = useAuth();
  // console.log(user);
  const location = useLocation();

  if (loading) {
    return null;
  }

  if (user) {
    return <Navigate to={redirectTo} state={{ from: location.pathname }} replace />;
  }

  return children;
};

export default PublicRoute;
