import React from 'react';

interface ColorSliderProps {
  title?: string;
  value?: string;
  onChange?: (color: string) => void;
  className?: string;
}

const ColorSlider: React.FC<ColorSliderProps> = ({ title, value = 'hsl(0, 100%, 50%)', onChange, className = '' }) => {
  return (
    <div className={`mb-4 color-slider ${className}`}>
      <label className="inline-block text-[#495057] font-medium text-xs md:text-lg mb-2">{title}</label>
      <div className="relative mt-2">
        {/* Color spectrum track */}
        <div
          className="h-[2.75px] rounded-full"
          style={{
            background: `linear-gradient(to right,
              hsl(0, 100%, 50%),
              hsl(60, 100%, 50%),
              hsl(120, 100%, 50%),
              hsl(180, 100%, 50%),
              hsl(240, 100%, 50%),
              hsl(300, 100%, 50%),
              hsl(360, 100%, 50%))`
          }}
        />

        {/* Thumb/handle */}
        <input
          type="range"
          min="0"
          max="360"
          value={value ? parseInt(value.split(',')[0].slice(4)) : 0}
          onChange={(e) => {
            const hue = parseInt(e.target.value);
            onChange?.(`hsl(${hue}, 100%, 50%)`);
          }}
          style={
            {
              '--thumb-color': value,
              position: 'absolute',
              inset: 0,
              width: '100%',
              height: '4px'
            } as React.CSSProperties
          }
        />
      </div>
    </div>
  );
};

export default ColorSlider;
