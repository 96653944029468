import React from 'react';

import ImgBanner from '@/assets/images/img-hero-banner.png';
import Logo from '@/assets/images/logo512.png';

interface LandingTemplateProps {
  children: React.ReactNode;
}

const LandingTemplate: React.FC<LandingTemplateProps> = ({ children }) => {
  return (
    <section className="bg-[#FBFBFF] relative h-screen flex flex-col gap-10 items-center justify-end p-0 mx-auto md:flex-row overflow-hidden">
      <div className="relative hidden w-[45%] h-full rounded-[24px] md:block md:pl-6 md:py-6">
        <img className="absolute left-[44px] top-[44px] max-w-[80px]" src={Logo} alt="batjet-logo" />
        <img className="max-w-full h-full w-full object-cover rounded-[24px]" src={ImgBanner} alt="batjet-banner" />
      </div>

      <div className="w-full md:w-[55%] md:py-6 h-full overflow-y-auto">{children}</div>
    </section>
  );
};

export default LandingTemplate;
