import { zodResolver } from '@hookform/resolvers/zod';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as z from 'zod';

import React, { useState } from 'react';

import PasswordField from '@/components/atoms/PasswordField';
import InfoModal from '@/components/molecules/InfoModal';
import SuccessMessage from '@/components/molecules/SuccessMessage';
import { Button } from '@/components/ui/button';

import { useDeviceType } from '@/hooks/useDeviceType';

import Logo from '@/assets/images/logo512.png';

// form schema
const schema = z
  .object({
    password: z.string().min(8, 'Password must be consisting minimum 8 characters'),
    confirm_password: z.string()
  })
  .refine((data) => data.password === data.confirm_password, {
    message: 'Must be same with password',
    path: ['confirm_password']
  });

type FormData = z.infer<typeof schema>;

const ResetPasswordForm = () => {
  const [success, setSuccess] = useState(false);

  const { isMobileView } = useDeviceType();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isDirty }
  } = useForm<FormData>({
    resolver: zodResolver(schema),
    mode: 'onTouched',
    defaultValues: {
      password: '',
      confirm_password: ''
    }
  });

  const onSubmit: SubmitHandler<FormData> = async (data: FormData) => {
    try {
      console.log('Form submitted:', data);

      setSuccess(true);
    } catch (error) {
      console.error('Reset password error:', error);
    }
  };

  return (
    <div className="pb-10 h-full max-w-[616px] mx-auto laptop-h:h-fit md:pr-7 md:pt-8 border md:border-0">
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={`max-w-[450px] mt-7 gap-5 mx-auto flex flex-col ${isMobileView ? 'justify-between' : 'justify-center'} py-10 px-5 md:px-0 h-full laptop-h:h-fit`}
      >
        <div>
          <img className="max-w-[130px] mx-auto md:hidden" src={Logo} alt="batjet-logo" />
          <h1 className="mt-7 mb-2 text-xl text-center font-bold uppercase md:text-left md:text-[28px]">
            RESET PASSWORD
          </h1>
          <p className="hidden md:block text-lg text-secondary leading-[22.5px] mb-7">
            Enter your new password below to finish resetting your account. Once updated, you can log in with your new
            password.
          </p>
          {success ? (
            <SuccessMessage
              title="Password Reset Successfully"
              description="Your password has been updated! Use your new password to log in and get back to your account by clicking the button below."
            />
          ) : (
            <>
              <PasswordField
                className="mb-3 mt-5 md:mt-0"
                label="New Password"
                description="Password must be consisting minimum 8 characters"
                placeholder="Enter new password..."
                name="password"
                register={register}
                error={errors.password}
              />
              <PasswordField
                className="mb-3"
                label="Confirm New Password"
                placeholder="Enter new password..."
                name="confirm_password"
                register={register}
                error={errors.confirm_password}
              />
            </>
          )}
        </div>
        <div>
          <Button
            className="my-4 w-full rounded-[24px] text-base font-semibold h-[44px] md:h-[64px] md:text-[18px] uppercase"
            disabled={!isValid}
          >
            {success ? 'BACK TO LOGIN' : ' Reset Password'}
          </Button>
        </div>
      </form>

      {success && isMobileView && (
        <InfoModal
          isOpen={success}
          variant="success"
          title="Password Reset Successfully"
          message="Your password has been updated! Use your new password to log in and get back to your account by clicking the button below."
          buttonText="Back to Login"
          onButtonClick={() => {}}
        />
      )}
    </div>
  );
};

export default ResetPasswordForm;
