// components/forms/BudgetCreationForm.tsx
import { useForm } from 'react-hook-form';

import React from 'react';

import ColorSlider from '@/components/atoms/ColorSlider';
import DatePickerField from '@/components/atoms/DatePickerField';
import IconSelect from '@/components/atoms/IconSelect';
import InputField from '@/components/atoms/InputField';
import SelectField from '@/components/atoms/SelectField';
import SliderField from '@/components/atoms/SliderField';
import SwitchField from '@/components/atoms/SwitchField';
import TextAreaField from '@/components/atoms/TextAreaField';

import { BUDGET_ICONS } from '@/utils/budgetIcons';

import euroIcon from '@/assets/icons/euro.svg';

const periods = [
  { value: 'one-moneth', label: '1 Month' },
  { value: 'six-month', label: '6 Month' },
  { value: 'one-year', label: '1 Year' }
];

const currencies = [
  { value: 'USD', label: 'US Dollar' },
  { value: 'EUR', label: 'Euro' },
  { value: 'GBP', label: 'British Pound' }
];

interface BudgetCreationFormProps {
  onSubmit?: (data: any) => void;
  className?: string;
}

const BudgetCreationForm: React.FC<BudgetCreationFormProps> = ({ onSubmit, className }) => {
  const { register, watch, setValue } = useForm({
    defaultValues: {
      budgetIcon: '',
      budgetColor: 'hsl(0, 100%, 50%)',
      startDate: new Date(),
      endDate: new Date(),
      currency: '',
      period: '',
      autoRenewal: false,
      bmev: '',
      bmevThreshold: 50,
      btrt: '',
      btrtThreshold: 50
    }
  });

  const selectedIcon = watch('budgetIcon');
  const selectedColor = watch('budgetColor');

  return (
    <form className="space-y-6">
      <div className="space-y-6 w-full flex flex-col items-start justify-between md:flex-row md:gap-5 md:space-y-0">
        <InputField
          className="flex-1 w-full"
          label={'Name of budget'}
          placeholder="Enter budget name..."
          name="budgetName"
          type="text"
          register={register}
        />
        <InputField
          className="flex-1 w-full"
          label={'Budget type'}
          placeholder="Enter budget Type..."
          name="budgetName"
          type="text"
          register={register}
        />
      </div>
      <div className="space-y-6 w-full flex flex-col items-start justify-between md:flex-row md:gap-5 md:space-y-0">
        <div className="flex-1">
          <IconSelect
            name="budgetIcon"
            value={selectedIcon}
            onChange={(value) => setValue('budgetIcon', value)}
            icons={BUDGET_ICONS}
          />
          <ColorSlider
            className="mt-4"
            value={selectedColor}
            onChange={(color) => setValue('budgetColor', color)}
            title="Select a colour for your budget icon"
          />
        </div>
        <TextAreaField
          name="description"
          label="Budget Description"
          placeholder="Enter description..."
          register={register}
          className="flex-1 w-full"
        />
      </div>
      <div className="flex items-center gap-3">
        <div className="flex-1 flex items-start gap-3">
          <DatePickerField
            name="startDate"
            label="Budget starting date"
            register={register}
            value={watch('startDate')}
            onChange={(date) => date && setValue('startDate', date)}
            className="flex-1"
          />
          <DatePickerField
            name="endDate"
            label="Budget ending date"
            register={register}
            value={watch('endDate')}
            onChange={(date) => date && setValue('endDate', date)}
            className="flex-1"
          />
        </div>
        <SelectField
          name="period"
          options={periods}
          value={watch('period')}
          onChange={(value) => {
            console.log('Period changed:', value);
            setValue('period', value);
          }}
          variant="icon-only"
          className="md:flex-1 md:hidden"
        />
        <SelectField
          name="period"
          label="Select Period"
          placeholder="Select Period..."
          options={periods}
          register={register}
          value={watch('period')}
          onChange={(value) => {
            console.log('period changed:', value);
            setValue('period', value);
          }}
          className="hidden md:block flex-1"
        />
      </div>
      <div className="space-y-6 w-full flex flex-col md:items-start justify-between md:flex-row md:gap-5 md:space-y-0">
        <SelectField
          name="currency"
          label="Currency"
          placeholder="Select Currency"
          options={currencies}
          register={register}
          value={watch('currency')}
          onChange={(value) => {
            console.log('Currency changed:', value);
            setValue('currency', value);
          }}
          className="flex-1"
        />
        <SwitchField
          name="autoRenewal"
          label="Automatic Renewal"
          register={register}
          checked={watch('autoRenewal')}
          onCheckedChange={(checked) => setValue('autoRenewal', checked)}
          className="flex-1"
        />
      </div>

      <div className="space-y-6 w-full flex flex-col md:items-start justify-between md:flex-row md:gap-5 md:space-y-0">
        <div className="w-full flex-1 space-y-6">
          <InputField
            name="bmev"
            label="Budget maximum expense value (BMEV)"
            placeholder="e.g 100000"
            type="text"
            icon={euroIcon}
            iconAlt="Euro"
            register={register}
          />
          <SliderField
            name="bmevThreshold"
            label="Alerting threshold of BMEV"
            register={register}
            value={[watch('bmevThreshold')]}
            onValueChange={([value]) => setValue('bmevThreshold', value)}
            min={0}
            max={100}
            step={1}
            unit="%"
          />
        </div>
        <div className="w-full flex-1 space-y-6">
          <InputField
            name="btrt"
            label="Budget maximum expense value (BTRT)"
            placeholder="e.g 100000"
            type="text"
            icon={euroIcon}
            iconAlt="Euro"
            register={register}
          />
          <SliderField
            name="btrtThreshold"
            label="Alerting threshold of BTRT"
            register={register}
            value={[watch('btrtThreshold')]}
            onValueChange={([value]) => setValue('btrtThreshold', value)}
            min={0}
            max={100}
            step={1}
            unit="%"
          />
        </div>
      </div>
    </form>
  );
};

export default BudgetCreationForm;
