import TermsCard from '@/components/molecules/TermsCard';
import LandingTemplate from '@/components/templates/LandingTemplate';
import chevronLeft from '@/assets/icons/chevron-left.svg';
import { Button } from '@/components/ui/button';
import { useNavigate } from 'react-router-dom';
import MobileAppBar from '@/components/molecules/MobileAppBar';
import { useDeviceType } from '@/hooks/useDeviceType';

const PrivacyPolicyPage = () => {
  const navigate = useNavigate();
  const { isMobileView } = useDeviceType();

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <LandingTemplate>
      <div className="md:py-7 h-full max-h-full flex flex-col">
        {isMobileView ? (
          <MobileAppBar title="Privacy Policy" />
        ) : (
          <header className="hidden md:flex items-center gap-5 mb-7">
            <Button onClick={handleBack} size={'icon'} variant={'icon'}>
              <img className="max-h-full" src={chevronLeft} alt="icon" />
            </Button>
            <h2 className="uppercase md:text-[28px] font-bold">Privacy Policy</h2>
          </header>
        )}

        <div className="px-4 md:px-5 flex flex-col gap-6 h-full max-h-full overflow-y-auto py-4 md:py-6 md:pb-8 md:bg-white md:rounded-[24px] md:border md:mr-8">
          <TermsCard />
        </div>
      </div>
    </LandingTemplate>
  );
};

export default PrivacyPolicyPage;
