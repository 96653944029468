import { apiService } from '@/api/apiConfig';
import { ProfileCreationData, ProfileCreationResponse } from '@/services/types/user.types';

// error handler
const handleUserError = (error: any): Error => {
  return error instanceof Error ? error : new Error('User operation failed');
};

export const userService = {
  createProfile: async (profileData: ProfileCreationData): Promise<ProfileCreationResponse> => {
    try {
      // if there's a profile picture, handle it with FormData
      if (profileData.profile_pic) {
        const formData = new FormData();
        formData.append('profile_pic', profileData.profile_pic);

        (Object.keys(profileData) as Array<keyof ProfileCreationData>).forEach((key) => {
          if (key !== 'profile_pic' && profileData[key]) {
            formData.append(key, String(profileData[key]));
          }
        });

        return await apiService.request<ProfileCreationResponse>({
          method: 'POST',
          url: '/user',
          data: formData,
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
      }

      // if no profile picture, send JSON data
      return await apiService.request<ProfileCreationResponse>({
        method: 'POST',
        url: '/user',
        data: profileData
      });
    } catch (error) {
      throw handleUserError(error);
    }
  }
};
