import React, { useCallback, useEffect, useState } from 'react';

interface CountdownTimerProps {
  duration?: number; // in seconds
  timeLeft?: number;
  onComplete?: () => void;
  isActive: boolean;
  onActiveChange?: (isActive: boolean) => void;
}

const CountdownTimer: React.FC<CountdownTimerProps> = ({
  duration = 60,
  timeLeft: initialTimeLeft,
  onComplete,
  isActive,
  onActiveChange
}) => {
  const [timeLeft, setTimeLeft] = useState(initialTimeLeft || duration);

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (isActive && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => {
          if (prevTime <= 1) {
            onActiveChange?.(false);
            onComplete?.();
            return duration;
          }
          return prevTime - 1;
        });
      }, 1000);
    }

    return () => {
      if (timer) clearInterval(timer);
    };
  }, [isActive, timeLeft, duration, onComplete, onActiveChange]);

  const formatTime = useCallback((seconds: number): string => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins}:${secs.toString().padStart(2, '0')}`;
  }, []);

  if (!isActive) return null;

  return <span className="text-gray-500"> {formatTime(timeLeft)} </span>;
};

export default CountdownTimer;
