import { FieldError, FieldErrors, UseFormRegister } from 'react-hook-form';

import React, { useEffect, useRef, useState } from 'react';

import arrowIcon from '@/assets/icons/arrow.svg';

type FormFieldError = FieldError | undefined | FieldErrors<any>;

interface Option {
  value: string;
  label: string;
}

type SelectVariant = 'default' | 'icon-only';

interface SelectFieldProps {
  name: string;
  label?: string;
  description?: string;
  placeholder?: string;
  value?: string;
  onChange?: (value: string) => void;
  disabled?: boolean;
  register?: UseFormRegister<any>;
  error?: FormFieldError;
  className?: string;
  options: Option[];
  variant?: SelectVariant;
}

const SelectField: React.FC<SelectFieldProps> = ({
  name,
  label = '',
  description,
  placeholder = 'Select an option',
  value,
  onChange,
  disabled = false,
  register,
  error,
  className,
  options,
  variant = 'default'
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef<HTMLDivElement>(null);

  const registerProps = register ? register(name) : null;

  const handleSelect = (selectedValue: string) => {
    if (registerProps) {
      registerProps.onChange({
        target: { value: selectedValue, name }
      });
    }

    if (onChange) {
      onChange(selectedValue);
    }

    setIsOpen(false);
  };

  const errorMessage = error && 'message' in error ? error.message : '';
  const selectedOption = options.find((opt) => opt.value === value);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (selectRef.current && !selectRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className={`${className ? className : ''} relative`} ref={selectRef}>
      <label className="inline-block text-[#495057] font-medium text-xs md:text-lg mb-2">{label}</label>
      <button
        type="button"
        onClick={() => !disabled && setIsOpen(!isOpen)}
        className={`bg-lightGray rounded-[20px] border w-full font-medium text-base
          ${variant === 'default' ? 'p-[0.585rem] md:p-[1.215rem] px-4' : 'p-2 md:p-3'}
          flex items-center
          ${variant === 'default' ? 'justify-between' : 'justify-center'}
          ${variant === 'icon-only' ? 'min-w-[44px] min-h-[44px] rounded-full' : ''}
          ${error ? 'border-red-500' : 'hover:border-[#ADB5BD]'}
          ${disabled ? 'cursor-not-allowed opacity-75' : 'cursor-pointer'}
        `}
      >
        {variant === 'default' && (
          <span className={`${!selectedOption ? 'text-[#93999F]' : 'text-[#495057]'}`}>
            {selectedOption?.label || placeholder}
          </span>
        )}
        <img
          className={`transform transition-transform duration-200 rotate-180 ${isOpen ? 'rotate-0' : ''}`}
          src={arrowIcon}
          alt="arrow-icon"
        />
      </button>

      {isOpen && (
        <div
          className={`absolute z-10 mt-1 bg-white border rounded-lg shadow-lg right-0
    ${variant === 'default' ? 'w-full' : 'w-[140px]'}
  `}
        >
          <div className="py-1 max-h-60 overflow-auto">
            {options.map((option) => (
              <button
                key={option.value}
                type="button"
                className={`w-full px-4 py-2 text-left text-base hover:bg-lightGray
            ${option.value === value ? 'text-primary font-medium' : 'text-[#495057]'}
          `}
                onClick={() => handleSelect(option.value)}
              >
                {option.label}
              </button>
            ))}
          </div>
        </div>
      )}

      {(errorMessage || description) && (
        <p className={`mt-2 text-xs ${errorMessage ? 'text-red-600' : 'text-[#93999F]'}`}>
          {(errorMessage as String) || description}
        </p>
      )}

      {register && (
        <input
          type="hidden"
          name={name}
          value={value || ''}
          {...registerProps}
          onChange={(e) => {
            registerProps?.onChange(e);
            onChange?.(e.target.value);
          }}
        />
      )}
    </div>
  );
};

export default SelectField;
