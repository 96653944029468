import React from 'react';

import iconCheck from '@/assets/icons/check.svg';

interface Plan {
  id: 'basic' | 'standard' | 'pro';
  name: string;
  price: string;
  benefits: string[];
}

interface SubscriptionPlanBoxProps {
  plan: Plan;
  isActive: boolean;
  onSelect: (planId: string) => void;
}

const SubscriptionPlanBox: React.FC<SubscriptionPlanBoxProps> = ({ plan, isActive, onSelect }) => {
  return (
    <div
      onClick={() => onSelect(plan.id)}
      className={`border-2 rounded-[16px] p-[0.85rem] py-[0.6rem] cursor-pointer transition-colors md:flex-1 md:pb-6
          ${isActive ? 'border-primary bg-lightPurple' : 'border-lightGray bg-transparent'}`}
    >
      <header className="flex items-center justify-between mb-[0.185rem] md:mb-[0.65rem]">
        <p className="md:text-lg">{plan.name}</p>
        <span
          className={`flex items-center justify-center rounded-full border-2 w-[20px] h-[20px]
            ${isActive ? 'border-primary' : 'border-lightGray'}`}
        >
          <span
            className={`bg-primary rounded-full w-[12px] h-[12px]
              ${isActive ? 'block' : 'hidden'}`}
          ></span>
        </span>
      </header>
      <h3 className="uppercase font-bold text-2xl md:text-[28px] md:border-b md:pb-[0.65rem]">
        {plan.price}
        {plan.id !== 'basic' && <span className="text-base font-normal capitalize ml-[3px] md:text-lg">/Month</span>}
      </h3>

      <div className="hidden mt-2 md:block">
        <p className="text-sm">Benefit</p>
        <ul className="mt-[0.9rem] flex flex-col gap-4">
          {plan.benefits.map((benefit, index) => (
            <li key={index} className="flex items-start gap-[0.6rem]">
              <span className="flex items-center justify-center rounded-full bg-lightPurple min-w-[20px] min-h-[20px]">
                <img src={iconCheck} alt="check-icon" />
              </span>
              <p className="leading-[19.5px] md:text-sm">{benefit}</p>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default SubscriptionPlanBox;
