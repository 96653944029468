import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FieldError, FieldErrors, UseFormRegister } from 'react-hook-form';

import React from 'react';

import calendarIcon from '@/assets/icons/calender.svg';

type FormFieldError = FieldError | undefined | FieldErrors<any>;

interface DatePickerFieldProps {
  name: string;
  label?: string;
  description?: string;
  placeholder?: string;
  value?: Date | null;
  onChange?: (date: Date | null) => void;
  disabled?: boolean;
  register?: UseFormRegister<any>;
  error?: FormFieldError;
  className?: string;
}

const DatePickerField: React.FC<DatePickerFieldProps> = ({
  name,
  label,
  description,
  placeholder = 'Select date',
  value,
  onChange,
  disabled = false,
  register,
  error,
  className
}) => {
  // get error message
  const errorMessage = error && 'message' in error ? error.message : '';

  const CustomInput = React.forwardRef<HTMLDivElement, { value?: string; onClick?: () => void }>(
    ({ value, onClick }, ref) => (
      <div
        className={`h-[44px] md:h-[64.88px] bg-lightGray rounded-[20px] border w-full font-medium text-xs md:text-base p-[0.585rem] md:p-[1.215rem] px-4 pr-3
          flex items-center justify-between cursor-pointer
          ${error ? 'border-red-500' : 'hover:border-[#ADB5BD]'}`}
        onClick={onClick}
        ref={ref}
      >
        <span className={`${!value ? 'text-[#93999F]' : 'text-[#495057]'}`}>{value || placeholder}</span>
        <img src={calendarIcon} alt="calendar-icon" />
      </div>
    )
  );

  CustomInput.displayName = 'CustomInput';

  const handleDateChange = (date: Date | null) => {
    if (register && date) {
      const { onChange: registeredOnChange } = register(name);
      registeredOnChange({ target: { value: date } });
    }
    if (onChange) {
      onChange(date);
    }
  };

  return (
    <div className={`${className ? className : ''}`}>
      {label && <label className="inline-block text-[#495057] font-medium text-xs md:text-lg mb-2">{label}</label>}
      <ReactDatePicker
        selected={value}
        onChange={handleDateChange}
        disabled={disabled}
        customInput={<CustomInput />}
        wrapperClassName="w-full"
        dateFormat="MMM dd, yyyy"
        calendarClassName="rounded-lg shadow-lg border border-gray-200"
      />
      {(errorMessage || description) && (
        <p className={`mt-2 text-xs ${errorMessage ? 'text-red-600' : 'text-[#93999F]'}`}>
          {(errorMessage as String) || description}
        </p>
      )}
    </div>
  );
};

export default DatePickerField;
