import { PAGE } from '@/routes';

import { useState } from 'react';

import { useNavigate } from 'react-router-dom';

import MobileAppBar from '@/components/molecules/MobileAppBar';
import ConfirmTermsSection from '@/components/organisms/ConfirmTermsSection';
import OnboardingCarousel from '@/components/organisms/OnboardingCarousel';
import { Button } from '@/components/ui/button';

import { useDeviceType } from '@/hooks/useDeviceType';

const OverviewPage = () => {
  const [displayTerms, setDisplayTerms] = useState(false);
  const { isMobileView } = useDeviceType();
  const navigate = useNavigate();

  const handleNextStep = () => {
    setDisplayTerms(true);
  };

  const handleOnConfirm = () => {
    navigate(`${PAGE.ONBOARDING.path}/${PAGE.ONBOARDING.children.SUBSCRIPTION}`);
  };

  return (
    <div className={`flex flex-col ${displayTerms ? 'justify-start' : 'justify-between'} min-h-full md:max-h-full`}>
      {isMobileView && (
        <>
          {displayTerms ? (
            <MobileAppBar
              title="Profile Creation"
              onBack={() => {
                setDisplayTerms(false);
              }}
            />
          ) : (
            <Button onClick={handleNextStep} variant={'text'} className="block py-5 ml-auto mr-0 font-semibold">
              Skip Onboarding
            </Button>
          )}
        </>
      )}
      {displayTerms ? (
        <ConfirmTermsSection onConfirm={handleOnConfirm} />
      ) : (
        <OnboardingCarousel onSkip={handleNextStep} onComplete={handleNextStep} />
      )}
    </div>
  );
};

export default OverviewPage;
