import SidebarIcon from '@/components/atoms/SidebarIcon';
import React, { useState } from 'react';

import { MOBILE_ICON_POSITIONS, WEB_ICON_POSITIONS } from '@/constants/icons_sprite';

interface SidebarLinkProps {
  label: string;
  iconKey: any;
  isActive: boolean;
  onClick: () => void;
}

const SidebarLink: React.FC<SidebarLinkProps> = ({ label, iconKey, isActive, onClick }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <button
      type="button"
      onClick={onClick}
      className="group relative w-full flex items-center justify-start gap-[0.875rem] pl-[32px] h-[52px] hover:text-primary"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {isActive && (
        <>
          <span className="absolute h-full w-[53px] top-0 left-0 sidebarLink-gradient opacity-20"></span>
          <span className="absolute h-full w-[4px] top-0 right-0 bg-primary rounded-l-full"></span>
        </>
      )}
      <SidebarIcon iconKey={iconKey} isActive={isActive || isHovered} />
      <span className={`text-${isActive ? 'primary' : 'secondary'} font-semibold text-base group-hover:text-primary`}>
        {label}
      </span>
    </button>
  );
};

export default SidebarLink;
