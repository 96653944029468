import React, { useState } from 'react';

import { Button } from '@/components/ui/button';

import { useDeviceType } from '@/hooks/useDeviceType';

// images
import imgOnboarding1 from '@/assets/images/onboarding-1.png';
import imgOnboarding2 from '@/assets/images/onboarding-2.png';
import imgOnboarding3 from '@/assets/images/onboarding-3.png';
import imgOnboarding4 from '@/assets/images/onboarding-4.png';
import imgOnboarding5 from '@/assets/images/onboarding-5.png';

const steps = [
  {
    img: imgOnboarding1,
    title: 'Create a budget',
    description:
      'Budget is an important tool to manage the allocation of funds and invoices. Within Budget, there are Budget Maximum Expense Value (BMEV) and Budget Total Revenue Value (BTRT) which ensure finances are managed properly.'
  },
  {
    img: imgOnboarding2,
    title: 'Create a Bank Account',
    description:
      'Bank Account is an entity in the budget that serves as a place to manage cash flow. You can set the initial amount and allocate invoices to the bank account.'
  },
  {
    img: imgOnboarding3,
    title: 'Create an Accounting Center',
    description:
      'Accounting Center is a sub-entity of Managerial Accounting Center that you can customize. There are four categories: Department, Product and Services, Projects, and Product. Each Accounting Center has an Accounting Center Maximum Expense Value (ACMEV) and an Accounting Center Revenue Target (ACRT).'
  },
  {
    img: imgOnboarding4,
    title: 'Create an Expense & Revenue Category',
    description:
      'Expense Categories and Revenue Categories are sub-entities of Managerial Accounting Center that you can customize. Every invoice created will be categorized into Expense or Revenue Categories.'
  },
  {
    img: imgOnboarding5,
    title: 'Upload an Invoices',
    description:
      'You can create and allocate invoices to each entity in the budget. Invoices created can be either paid invoices or upcoming invoices, ensuring organized financial management.'
  }
];

interface OnboardingCarouselProps {
  onComplete: () => void;
  onSkip?: () => void;
}

const OnboardingCarousel: React.FC<OnboardingCarouselProps> = ({ onComplete, onSkip }) => {
  const [index, setIndex] = useState(0);

  const { isMobileView } = useDeviceType();

  const handleNextClick = () => {
    if (index === steps.length - 1) {
      onComplete();
      return;
    }

    if (index < steps.length) setIndex((prev) => prev + 1);
  };

  const handlePreviousClick = () => {
    if (index > 0) setIndex((prev) => prev - 1);
  };

  return (
    <div className={`h-[80%] ${isMobileView ? 'pt-32' : ''}  flex-1 md:h-full flex flex-col`}>
      <div
        key={index}
        className={` ${!isMobileView ? 'bg-white border rounded-[24px] py-6 min-h-[480px] mt-10' : 'flex-1'}`}
      >
        <img className="mb-8 mx-auto" src={steps[index].img} alt="onboarding-1-img" />
        <h2 className={`${isMobileView ? 'text-xl' : 'text-2xl'} mb-2 font-bold text-center`}>{steps[index].title}</h2>
        <p
          className={`text-center px-5 ${isMobileView ? 'text-sm leading-[17.07px]' : 'text-lg leading-[22px] px-10'} max-w-[900px] mx-auto  text-secondary`}
        >
          {steps[index].description}
        </p>
      </div>

      {/* Steps Progress */}
      {isMobileView && (
        <div className="w-fit mx-auto mt-10 flex items-center gap-1">
          {steps.map((_, i) => {
            return (
              <span
                key={i}
                className={`h-[4px] ${index === i ? 'w-[34px]' : 'w-[14px]'} ${index === i ? 'bg-primary' : 'bg-lightGray'} rounded-full`}
              ></span>
            );
          })}
        </div>
      )}

      <div className="flex items-center justify-between gap-4 px-4 mt-10">
        {!isMobileView ? (
          <>
            <Button
              onClick={() => {
                if (onSkip) onSkip();
              }}
              variant={'text'}
              className="py-5 font-semibold text-lg"
            >
              Skip
            </Button>
            <div className="flex items-center gap-7">
              <p>
                {index + 1} of {steps.length}
              </p>
              <Button
                disabled={index === steps.length}
                onClick={handleNextClick}
                className="rounded-[24px] uppercase text-lg h-[64px] px-6"
              >
                Next
              </Button>
            </div>
          </>
        ) : (
          <>
            <Button
              disabled={index === 0}
              onClick={handlePreviousClick}
              className="rounded-full uppercase text-base flex-1"
              variant={'outline'}
            >
              Previous
            </Button>
            <Button
              disabled={index === steps.length}
              onClick={handleNextClick}
              className="rounded-full uppercase text-base flex-1"
            >
              Next
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default OnboardingCarousel;
