import { FieldError, FieldErrors, UseFormRegister } from 'react-hook-form';

import React from 'react';

import { Slider } from '@/components/ui/slider';

import { cn } from '@/lib/utils';

type FormFieldError = FieldError | undefined | FieldErrors<any>;

interface SliderFieldProps {
  name: string;
  label?: string;
  description?: string;
  value?: number[];
  onValueChange?: (value: number[]) => void;
  disabled?: boolean;
  register?: UseFormRegister<any>;
  error?: FormFieldError;
  className?: string;
  min?: number;
  max?: number;
  step?: number;
  unit?: string;
}

const SliderField: React.FC<SliderFieldProps> = ({
  name,
  label,
  description,
  value = [0],
  onValueChange,
  disabled = false,
  register,
  error,
  className,
  min = 0,
  max = 100,
  step = 1,
  unit = '%'
}) => {
  const handleValueChange = (newValue: number[]) => {
    if (register) {
      const { onChange: registeredOnChange } = register(name);
      registeredOnChange({ target: { value: newValue[0], name } });
    }
    if (onValueChange) {
      onValueChange(newValue);
    }
  };

  const errorMessage = error && 'message' in error ? error.message : '';

  return (
    <div className={`${className || ''} space-y-1`}>
      {label && <label className="text-[#495057] font-medium text-xs md:text-lg">{label}</label>}
      <div className=" relative pt-5 pb-5">
        <Slider
          onValueChange={handleValueChange}
          value={value}
          max={max}
          min={min}
          step={step}
          disabled={disabled}
          className="relative flex w-full touch-none select-none items-center"
          thumb={
            <div className="absolute border border-primary rounded-md px-2 py-1 bg-white min-w-[40px] text-center cursor-pointer">
              <span className="text-sm text-[#495057] whitespace-nowrap">
                {value[0]}
                {unit}
              </span>
            </div>
          }
        />
      </div>
      {(errorMessage || description) && (
        <p className={`mt-2 text-xs ${errorMessage ? 'text-red-600' : 'text-[#93999F]'}`}>
          {(errorMessage as String) || description}
        </p>
      )}
      {register && <input type="hidden" name={name} value={value[0]} />}
    </div>
  );
};

export default SliderField;
