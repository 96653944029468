import { useState, useEffect } from 'react';
import isMobile from 'is-mobile';

interface DeviceType {
  isMobileView: boolean;
  width: number;
}

export const useDeviceType = (breakpoint: number = 768): DeviceType => {
  const [width, setWidth] = useState<number>(typeof window !== 'undefined' ? window.innerWidth : 0);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Cleanup
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const isMobileView = width < breakpoint || isMobile();

  return {
    isMobileView,
    width
  };
};
