import SidebarLink from '@/components/molecules/SidebarLink';
import React, { useState } from 'react';
import Logo from '@/assets/images/logo512.png';
import { useNavigate } from 'react-router-dom';

const WebSidebar = () => {
  const [activeLink, setActiveLink] = useState('dashboard');
  const navigate = useNavigate();

  const handleNavigation = (path: string) => {
    setActiveLink(path);
    // navigate(`/${path}`);
  };

  return (
    <aside className="w-[20%] max-w-[260px] min-w-[260px] pt-[3rem] bg-white shadow-[2px_0px_4px_0px_#00000040]">
      <img className="mx-auto w-[80px] h-[77px]" src={Logo} alt="Batjet Logo" />
      <section className="mt-10">
        <SidebarLink
          label="Dashboard"
          iconKey="dashboard"
          isActive={activeLink === 'dashboard'}
          onClick={() => handleNavigation('dashboard')}
        />
        <SidebarLink
          label="Budget"
          iconKey="budget"
          isActive={activeLink === 'budget'}
          onClick={() => handleNavigation('budget')}
        />
        <SidebarLink
          label="Invoice"
          iconKey="invoice"
          isActive={activeLink === 'invoice'}
          onClick={() => handleNavigation('invoice')}
        />
        <SidebarLink
          label="Commitment"
          iconKey="commitment"
          isActive={activeLink === 'commitment'}
          onClick={() => handleNavigation('commitment')}
        />
      </section>
    </aside>
  );
};

export default WebSidebar;
