import React from 'react';

import WebSidebar from '@/components/organisms/WebSidebar';

import DashboardHeader from '@/components/organisms/DashboardHeader';
import { useDeviceType } from '@/hooks/useDeviceType';
import MobileBottonNavigation from '@/components/molecules/MobileBottonNavigation';

interface DashboardTemplateProps {
  children: React.ReactNode;
}

const DashboardTemplate: React.FC<DashboardTemplateProps> = ({ children }) => {
  const { isMobileView } = useDeviceType();

  return (
    <div className="flex flex-col md:flex-row items-stretch h-screen bg-primary md:bg-transparent ">
      {/* SIDEBAR NAVIGATION - WEB */}
      {isMobileView ? '' : <WebSidebar />}
      <div className="overflow-y-auto relative bg-[#FBFBFF] w-full flex-1 md:flex-auto md:flex md:flex-col rounded-b-[36px] md:rounded-none shadow-[0px_20px_20px_0px_#00000033] md:shadow-none">
        {/* HEADER - WEB */}
        {!isMobileView && <DashboardHeader />}

        {/* MAIN */}
        <main className="flex-1 p-6 overflow-x-hidden">{children}</main>

        {/* BOTTOM BAR NAVIGATION - MOBILE */}
      </div>
      {isMobileView && (
        <div className="h-[94px] px-6 flex items-center">
          <MobileBottonNavigation />
        </div>
      )}
    </div>
  );
};

export default DashboardTemplate;
