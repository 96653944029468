import React from 'react';

import ForgotPasswordForm from '@/components/organisms/ForgotPasswordForm';
import LandingTemplate from '@/components/templates/LandingTemplate';

const ForgotPasswordPage = () => {
  return (
    <LandingTemplate>
      <ForgotPasswordForm />
    </LandingTemplate>
  );
};

export default ForgotPasswordPage;
