import { FieldError, FieldErrors, UseFormRegister } from 'react-hook-form';

import React from 'react';

import { Textarea } from '@/components/ui/textarea';

type FormFieldError = FieldError | undefined | FieldErrors<any>;

interface TextAreaFieldProps {
  name: string;
  label?: string;
  description?: string;
  placeholder?: string;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  disabled?: boolean;
  register?: UseFormRegister<any>;
  error?: FormFieldError;
  className?: string;
  rows?: number;
}

const TextAreaField: React.FC<TextAreaFieldProps> = ({
  name,
  label,
  description,
  placeholder,
  value,
  onChange,
  disabled = false,
  register,
  error,
  className,
  rows = 3
}) => {
  const textareaProps = register
    ? register(name)
    : {
        value: value || '',
        onChange: onChange
      };

  const errorMessage = error && 'message' in error ? error.message : '';

  return (
    <div className={`${className ? className : ''}`}>
      {label && <label className="inline-block text-[#495057] font-medium text-xs md:text-lg mb-2">{label}</label>}
      <Textarea
        className={`bg-lightGray rounded-[20px] border w-full font-medium text-base p-[0.585rem] md:p-[1.215rem] px-4
          outline-none placeholder:text-[#93999F] text-[#495057]
          disabled:cursor-not-allowed disabled:placeholder:text-[#93999f91]
          focus:outline-none focus:border-[#ADB5BD] focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-offset-0
          resize-none h-[128px]
          ${error ? 'border-red-500' : ''}
        `}
        disabled={disabled}
        placeholder={placeholder}
        rows={rows}
        {...textareaProps}
      />
      {(errorMessage || description) && (
        <p className={`mt-2 text-xs ${errorMessage ? 'text-red-600' : 'text-[#93999F]'}`}>
          {(errorMessage as String) || description}
        </p>
      )}
    </div>
  );
};

export default TextAreaField;
