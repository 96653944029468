import { UseFormRegister, UseFormWatch } from 'react-hook-form';

import React from 'react';

interface IconSelectProps {
  label?: string;
  name: string;
  value?: string;
  onChange?: (value: string) => void;
  register?: UseFormRegister<any>;
  className?: string;
  icons: Array<{
    id: string;
    icon: string;
    name: string;
  }>;
}

const IconSelect: React.FC<IconSelectProps> = ({
  label = 'Select your budget icon',
  name,
  value,
  onChange,
  className = '',
  icons
}) => {
  return (
    <div className={className}>
      {label && <label className="inline-block text-[#495057] font-medium text-xs md:text-lg mb-2">{label}</label>}
      <div className="mt-[0.2rem] flex items-start justify-between md:justify-start gap-3">
        {icons.map((icon) => (
          <button
            type="button"
            className={`border rounded-[16px] w-[98px] h-[96px] flex items-center justify-center bg-white p-3
            ${value === icon.id ? 'border-primary' : 'border-white hover:border-[#ADB5BD]'}`}
            key={icon.id}
            onClick={() => onChange?.(icon.id)}
          >
            <img src={icon.icon} alt={icon.name} />
          </button>
        ))}
      </div>
      <input type="hidden" name={name} value={value || ''} />
    </div>
  );
};

export default IconSelect;
