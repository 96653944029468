import { terms } from '@/utils/constants';
import React from 'react';

const TermsCard = () => {
  return (
    <article className="flex flex-col gap-6">
      {terms &&
        terms?.map((item, index) => (
          <div key={index} className="flex flex-col gap-[0.2rem]">
            <h4 className="leading-[24px] md:text-lg md:font-bold">{item.title}</h4>
            <p className="text-sm text-secondary leading-[19.5px] md:text-base">{item.description}</p>
          </div>
        ))}
    </article>
  );
};

export default TermsCard;
