import React, { useState } from 'react';
import { UseFormRegister, FieldError, FieldErrors } from 'react-hook-form';
import iconEyeShow from '@/assets/icons/eye-show.svg';
import iconEyeHide from '@/assets/icons/eye-hide.svg';

type FormFieldError = FieldError | undefined | FieldErrors<any>;

interface PasswordFieldProps {
  name: string;
  label?: string;
  description?: string;
  placeholder?: string;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  register?: UseFormRegister<any>;
  error?: FormFieldError;
  className?: string;
}

const PasswordField: React.FC<PasswordFieldProps> = ({
  name,
  label,
  description,
  placeholder,
  value,
  onChange,
  disabled = false,
  register,
  error,
  className
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setShowPassword(!showPassword);
  };

  const isControlled = value !== undefined && onChange !== undefined;
  const inputProps = isControlled ? { value, onChange } : register ? { ...register(name) } : {};

  const errorMessage = error && 'message' in error ? error.message : '';

  return (
    <div className={`${className ? className : ''}`}>
      {label && <label className="inline-block text-[#495057] font-medium text-xs md:text-lg mb-2">{label}</label>}
      <div className="relative">
        <input
          className={`
            bg-lightGray rounded-[20px] border w-full text-base placeholder:font-medium p-[0.585rem] md:p-[1.215rem] px-4 outline-none
            placeholder:text-[#93999F] text-[#495057] disabled:cursor-not-allowed
            disabled:placeholder:text-[#93999f91] focus:outline-none focus:border-[#ADB5BD]
            ${!showPassword ? 'font-extrabold' : ''}
            ${error ? 'border-red-500' : ''}
          `}
          disabled={disabled}
          type={showPassword ? 'text' : 'password'}
          placeholder={placeholder}
          {...inputProps}
        />
        <button
          className="absolute top-[50%] right-[16px] translate-y-[-50%] focus:outline-primary"
          onClick={togglePasswordVisibility}
          type="button"
        >
          <img
            className="max-w-full"
            src={showPassword ? iconEyeHide : iconEyeShow}
            alt={showPassword ? 'Hide password' : 'Show password'}
          />
        </button>
      </div>
      {(errorMessage || description) && (
        <p className={`mt-2 text-xs ${errorMessage ? 'text-red-600' : 'text-[#93999F]'}`}>
          {(errorMessage as String) || description}
        </p>
      )}
    </div>
  );
};

export default PasswordField;
