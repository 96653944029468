import React from 'react';

import { createBrowserRouter } from 'react-router-dom';
import { Navigate } from 'react-router-dom';

import BudgetsPage from '@/pages/Budgets';
import BudgetAddPage from '@/pages/Budgets/Add';
import BudgetDetailPage from '@/pages/Budgets/Detail';
import ForgotPasswordPage from '@/pages/ForgotPassword';
import LandingPage from '@/pages/Landing';
import LoginPage from '@/pages/Login';
import OnboardingLayout from '@/pages/Onboarding/OnboardingLayout';
import OverviewPage from '@/pages/Onboarding/Overview';
import ProfilePage from '@/pages/Onboarding/Profile';
import SubscriptionPage from '@/pages/Onboarding/Subscription';
import PrivacyPolicyPage from '@/pages/PrivacyPolicy';
import ResetPasswordPage from '@/pages/ResetPassword';
import SignUpPage from '@/pages/SignUp';
import TermsPage from '@/pages/Terms';
import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';
import NotFoundPage from '@/pages/NotFound';

import { Pages } from './types';
import DashboardPage from '@/pages/Dashboard';

export const PAGE: Pages = {
  LANDING: {
    path: '/',
    navTitle: 'Landing'
  },
  LOGIN: {
    path: '/login',
    navTitle: 'Login'
  },
  SIGNUP: {
    path: '/signup',
    navTitle: 'SignUp'
  },
  ONBOARDING: {
    path: '/onboarding',
    navTitle: 'Onboarding',
    children: {
      PROFILE: 'profile',
      OVERVIEW: 'overview',
      SUBSCRIPTION: 'subscription'
    }
  },
  TERMS: {
    path: '/terms',
    navTitle: 'Terms'
  },
  PRIVACY_POLICY: {
    path: '/privacy-policy',
    navTitle: 'PrivacyPolicy'
  },
  FORGOT_PASSWORD: {
    path: '/forgot-password',
    navTitle: 'ForgotPassword'
  },
  RESET_PASSWORD: {
    path: '/reset-password',
    navTitle: 'ResetPassword'
  },
  BUDGETS: {
    path: '/budgets',
    navTitle: 'Budgets',
    children: {
      ADD: 'add',
      DETAIL: ':budgetId'
    }
  },
  DASHBOARD: {
    path: '/dashboard',
    navTitle: 'Dashboard'
  }
} as const;

export const routes = createBrowserRouter([
  {
    path: PAGE.LANDING.path,
    element: React.createElement(PublicRoute, {
      children: React.createElement(LandingPage) as React.ReactElement,
      redirectTo: '/dashboard'
    }) as React.ReactElement
  },
  {
    path: PAGE.LOGIN.path,
    element: React.createElement(PublicRoute, {
      children: React.createElement(LoginPage) as React.ReactElement,
      redirectTo: '/dashboard'
    }) as React.ReactElement
  },
  {
    path: PAGE.SIGNUP.path,
    element: React.createElement(PublicRoute, {
      children: React.createElement(SignUpPage) as React.ReactElement,
      redirectTo: '/dashboard'
    }) as React.ReactElement
  },
  {
    path: PAGE.ONBOARDING.path,
    element: React.createElement(PrivateRoute, {
      children: React.createElement(OnboardingLayout) as React.ReactElement,
      redirectTo: '/login'
    }) as React.ReactElement,
    children: [
      {
        index: true,
        element: React.createElement(Navigate, {
          to: `${PAGE.ONBOARDING.path}/${PAGE.ONBOARDING.children.PROFILE}`,
          replace: true
        }) as React.ReactElement
      },
      {
        path: PAGE.ONBOARDING.children.PROFILE,
        element: React.createElement(ProfilePage) as React.ReactElement
      },
      {
        path: PAGE.ONBOARDING.children.OVERVIEW,
        element: React.createElement(OverviewPage) as React.ReactElement
      },
      {
        path: PAGE.ONBOARDING.children.SUBSCRIPTION,
        element: React.createElement(SubscriptionPage) as React.ReactElement
      }
    ]
  },
  // Public routes without auth check
  {
    path: PAGE.TERMS.path,
    element: React.createElement(TermsPage) as React.ReactElement
  },
  {
    path: PAGE.PRIVACY_POLICY.path,
    element: React.createElement(PrivacyPolicyPage) as React.ReactElement
  },
  {
    path: PAGE.FORGOT_PASSWORD.path,
    element: React.createElement(PublicRoute, {
      children: React.createElement(ForgotPasswordPage) as React.ReactElement,
      redirectTo: '/dashboard'
    }) as React.ReactElement
  },
  {
    path: PAGE.RESET_PASSWORD.path,
    element: React.createElement(PublicRoute, {
      children: React.createElement(ResetPasswordPage) as React.ReactElement,
      redirectTo: '/dashboard'
    }) as React.ReactElement
  },
  {
    path: PAGE.BUDGETS.path,
    element: React.createElement(PrivateRoute, {
      children: React.createElement(BudgetsPage) as React.ReactElement,
      redirectTo: '/login'
    }) as React.ReactElement,
    children: [
      {
        path: PAGE.BUDGETS.children.ADD,
        element: React.createElement(BudgetAddPage) as React.ReactElement
      },
      {
        path: PAGE.BUDGETS.children.DETAIL,
        element: React.createElement(BudgetDetailPage) as React.ReactElement
      }
    ]
  },
  {
    path: PAGE.DASHBOARD.path,
    element: React.createElement(PrivateRoute, {
      children: React.createElement(DashboardPage) as React.ReactElement,
      redirectTo: '/login'
    }) as React.ReactElement
  },
  // 404 route
  {
    path: '*',
    element: React.createElement(NotFoundPage) as React.ReactElement
  }
]);

export default routes;
