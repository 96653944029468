import React, { useState, useRef, ChangeEvent } from 'react';
import { Button } from '../ui/button'; // Assuming you have a Button component

interface UserProfilePicInputProps {
  defaultUserImg: string;
  cameraIcon: string;
  onImageChange?: (file: File | null) => void;
  onImageRemove?: () => void;
}

const UserProfilePicInput: React.FC<UserProfilePicInputProps> = ({
  defaultUserImg,
  cameraIcon,
  onImageChange,
  onImageRemove
}) => {
  const [previewImage, setPreviewImage] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result as string);
      };
      reader.readAsDataURL(file);
      onImageChange && onImageChange(file);
    }
  };

  const handleButtonClick = () => {
    fileInputRef.current?.click();
  };

  const handleRemoveImage = () => {
    // Reset the file input
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }

    // Clear the preview
    setPreviewImage(null);

    // Notify parent components
    onImageChange && onImageChange(null);
    onImageRemove && onImageRemove();
  };

  return (
    <div className="w-fit flex items-center gap-5">
      <div className="w-full relative p-5 rounded-full overflow-hidden">
        <span className="absolute bg-primary w-full h-full opacity-10 top-0 left-0"></span>
        <div className="relative">
          <img
            src={previewImage || defaultUserImg}
            alt="user-pfp"
            className="object-cover rounded-full w-[70px] h-[70px]"
          />
          <Button
            className="absolute bottom-[-2px] right-[-2px] p-0 h-[24.8px] w-[24.8px] rounded-full flex items-center justify-center md:hidden"
            onClick={handleButtonClick}
            type="button"
          >
            <img src={cameraIcon} alt="camera-icon" />
          </Button>
          <input type="file" ref={fileInputRef} onChange={handleImageChange} accept="image/*" className="hidden" />
        </div>
      </div>
      <Button
        className="rounded-full hidden items-center justify-center h-[39px] text-xs font-semibold md:flex"
        onClick={handleButtonClick}
        type="button"
        variant={'primary-reverse'}
      >
        UPLOAD IMAGE
      </Button>
      {previewImage && (
        <Button
          className="rounded-full hidden items-center justify-center h-[39px] text-xs font-semibold uppercase text-destructive md:flex"
          onClick={handleRemoveImage}
          type="button"
          variant={'ghost'}
        >
          Remove image
        </Button>
      )}
    </div>
  );
};

export default UserProfilePicInput;
