import { useState, useEffect } from 'react';
import './index.css';
import { RouterProvider } from 'react-router-dom';
import routes from './routes';
import isMobile from 'is-mobile';

import './App.css';
import SplashScreen from './pages/Splash';

function App() {
  const [isVisible, setIsVisible] = useState(true);
  const [isMobileDevice, setIsMobileDevice] = useState(false);

  useEffect(() => {
    setIsMobileDevice(isMobile());

    if (isMobileDevice) {
      const timer = setTimeout(() => {
        setIsVisible(false);
      }, 3000); // Show splash screen for 3 seconds on mobile devices

      return () => clearTimeout(timer);
    } else {
      setIsVisible(false);
    }
  }, [isMobileDevice]);

  if (isVisible && isMobileDevice) {
    return <SplashScreen />;
  }

  return <RouterProvider router={routes} />;
}

export default App;
