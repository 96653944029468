import SidebarIcon from '@/components/atoms/SidebarIcon';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const MobileBottonNavigation = () => {
  const [activeLink, setActiveLink] = useState('dashboard');
  const navigate = useNavigate();

  const handleNavigation = (path: string) => {
    setActiveLink(path);
    // navigate(`/${path}`);
  };

  return (
    <nav className="flex items-center justify-between w-full">
      <button className="relative" onClick={() => handleNavigation('dashboard')} type="button">
        <SidebarIcon iconKey={'dashboard'} isActive={activeLink === 'dashboard'} />
        {activeLink === 'dashboard' && (
          <span className="absolute h-[4px] w-[4px] bg-white rounded-full left-[50%] translate-x-[-50%] bottom-[-14px]"></span>
        )}
      </button>
      <button className="relative" onClick={() => handleNavigation('budget')} type="button">
        <SidebarIcon iconKey={'budget'} isActive={activeLink === 'budget'} />
        {activeLink === 'budget' && (
          <span className="absolute h-[4px] w-[4px] bg-white rounded-full left-[50%] translate-x-[-50%] bottom-[-14px]"></span>
        )}
      </button>{' '}
      <button className="relative" onClick={() => handleNavigation('invoice')} type="button">
        <SidebarIcon iconKey={'invoice'} isActive={activeLink === 'invoice'} />
        {activeLink === 'invoice' && (
          <span className="absolute h-[4px] w-[4px] bg-white rounded-full left-[50%] translate-x-[-50%] bottom-[-14px]"></span>
        )}
      </button>{' '}
      <button className="relative" onClick={() => handleNavigation('commitment')} type="button">
        <SidebarIcon iconKey={'commitment'} isActive={activeLink === 'commitment'} />
        {activeLink === 'commitment' && (
          <span className="absolute h-[4px] w-[4px] bg-white rounded-full left-[50%] translate-x-[-50%] bottom-[-14px]"></span>
        )}
      </button>
      <button className="relative" onClick={() => handleNavigation('profile')} type="button">
        <SidebarIcon iconKey={'profile'} isActive={activeLink === 'profile'} />
        {activeLink === 'profile' && (
          <span className="absolute h-[4px] w-[4px] bg-white rounded-full left-[50%] translate-x-[-50%] bottom-[-14px]"></span>
        )}
      </button>
    </nav>
  );
};

export default MobileBottonNavigation;
