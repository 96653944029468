import React, { useState } from 'react';

import Checkbox from '@/components/atoms/Checkbox';
import TermsCard from '@/components/molecules/TermsCard';
import { Button } from '@/components/ui/button';

import { terms } from '@/utils/constants';

interface ConfirmTermsSectionProps {
  onConfirm: () => void;
}

const ConfirmTermsSection: React.FC<ConfirmTermsSectionProps> = ({ onConfirm }) => {
  const [checked, setChecked] = useState(false);

  return (
    <>
      <div className="h-fit px-4 md:px-5 max-h-full overflow-y-auto flex flex-col gap-6 py-6 md:pb-8 md:bg-white md:rounded-[24px] md:border md:mt-8 scroll-smooth">
        <TermsCard />
        <Checkbox
          name="agreeTerms"
          checked={checked}
          onChange={(val) => setChecked(val)}
          label={
            <p className="text-xs font-medium md:text-sm select-none text-secondary leading-[0.9125rem]">
              I understand with BATJET Terminology.
            </p>
          }
        />
        <Button onClick={onConfirm} disabled={!checked} className="rounded-full uppercase text-base flex-1 md:hidden">
          continue to SUBSCRIPTION PLAN
        </Button>
      </div>
      <Button
        disabled={!checked}
        className="rounded-full uppercase text-base flex-1 hidden md:block mt-8 w-fit ml-auto mr-0 md:min-h-[64px] md:px-6"
        onClick={onConfirm}
      >
        continue to SUBSCRIPTION PLAN
      </Button>
    </>
  );
};

export default ConfirmTermsSection;
